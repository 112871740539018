import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  createWiseAccount,
  getWiseAccountRequirements,
  verifyPaypalConnectManually,
} from '../../util/api';
import * as log from '../../util/log';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/WisePayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/WisePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/WisePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/WisePayoutPage/SAVE_PAYOUT_DETAILS_ERROR';
export const FETCH_TRANSFER_WISE_REQUIREMENTS_REQUEST =
  'app/WisePayoutPage/FETCH_TRANSFER_WISE_REQUIREMENTS_REQUEST';
export const FETCH_TRANSFER_WISE_REQUIREMENTS_SUCCESS =
  'app/WisePayoutPage/FETCH_TRANSFER_WISE_REQUIREMENTS_SUCCESS';
export const FETCH_TRANSFER_WISE_REQUIREMENTS_ERROR =
  'app/WisePayoutPage/FETCH_TRANSFER_WISE_REQUIREMENTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  payoutDetailsSavedError: null,

  fetchWiseRequirementsInProgress: false,
  fetchWiseRequirementsError: null,
  wiseRequirements: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true, payoutDetailsSavedError: null };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSavedError: payload };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    case FETCH_TRANSFER_WISE_REQUIREMENTS_REQUEST:
      return {
        ...state,
        fetchWiseRequirementsInProgress: true,
        fetchWiseRequirementsError: null,
      };
    case FETCH_TRANSFER_WISE_REQUIREMENTS_ERROR:
      return {
        ...state,
        fetchWiseRequirementsInProgress: false,
        fetchWiseRequirementsError: payload,
      };
    case FETCH_TRANSFER_WISE_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        fetchWiseRequirementsInProgress: false,
        fetchWiseRequirementsError: null,
        wiseRequirements: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error.data, error: true });

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

export const fetchWiseRequirementsRequest = requestAction(
  FETCH_TRANSFER_WISE_REQUIREMENTS_REQUEST
);
export const fetchWiseRequirementsSuccess = successAction(
  FETCH_TRANSFER_WISE_REQUIREMENTS_SUCCESS
);
export const fetchWiseRequirementsError = errorAction(
  FETCH_TRANSFER_WISE_REQUIREMENTS_ERROR
);

// ================ Thunks ================ //

export const saveWisePayoutDetails = values => (dispatch, getState, sdk) => {
  dispatch(savePayoutDetailsRequest());

  return createWiseAccount(values)
    .then(() => {
      return dispatch(fetchCurrentUser());
    })
    .then(() => {
      return dispatch(savePayoutDetailsSuccess());
    })
    .catch(e => {
      dispatch(savePayoutDetailsError(e));
      throw e;
    });
};

export const fetchWiseRequirements = params => (dispatch, getState, sdk) => {
  dispatch(fetchWiseRequirementsRequest());
  return getWiseAccountRequirements(params)
    .then(response => {
      dispatch(fetchWiseRequirementsSuccess(response));
      return response;
    })
    .catch(e => {
      log.error(e);
      dispatch(fetchWiseRequirementsError(e));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser());
};
