import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';
import { ValidationError } from '../index';
import { string, func } from 'prop-types';

import css from './FieldPhoneNumber.module.css';

const DEFAULT_COUNTRY = 'it';

const FieldPhoneNumber = forwardRef((props, ref) => {
  const { initialValue } = props;
  const [touched, setTouched] = useState(false);
  const [phone, setPhone] = useState(initialValue);

  useEffect(() => {
    setPhone(initialValue);
  }, [initialValue]);

  useImperativeHandle(
    ref,
    () => {
      return {
        restart: () => {
          setTouched(false);
          setPhone('');
        },
      };
    },
    []
  );

  return (
    <Field
      {...props}
      render={renderProps => {
        const { input, id, label, rootClassName, className, inputClassName, meta, placeholder } = renderProps;
        const { name } = input;
        const inputProps = {
          ...input,
          name,
        };
        const { invalid, error } = meta;
        const hasError = !!(touched && invalid && error);
        const fieldMeta = { touched: hasError, error };
        const classes = classNames(rootClassName || css.root, className);

        // Workaround for us and cad bug https://github.com/bl00mber/react-phone-input-2/issues/377
        const onChange = (value, _, __, formattedValue) => {
          input.onChange(value);
          setPhone(formattedValue);
        };

        return (
          <div className={classes}>
            {label ? <label htmlFor={id}>{label}</label> : null}
            <input {...inputProps} className={css.hide} />
            <PhoneInput
              id={id}
              inputClass={inputClassName}
              value={phone}
              onChange={onChange}
              onBlur={() => setTouched(true)}
              country={DEFAULT_COUNTRY}
              specialLabel=""
              placeholder={placeholder}
            />
            <ValidationError fieldMeta={fieldMeta} />
          </div>
        );
      }}
    />
  );
});

FieldPhoneNumber.propTypes = {
  className: string,
  rootClassName: string,
  id: string.isRequired,
  name: string.isRequired,
  label: string,
  validate: func,
  initialValue: string,
};

export default FieldPhoneNumber;
