import React from "react";
import { displayPrice } from "../../../util/configHelpers";
import { formatMoney } from '../../../util/currency';
import classNames from "classnames";

import css from "./PriceMaybe.module.css";

const PriceMaybe = props => {
    const { price, publicData, validListingTypes, className = '', intl, perUnit } = props;
    const { listingType } = publicData || {};
  
    const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
    const showPrice = displayPrice(foundListingTypeConfig);
    if (!showPrice || !price) {
      return null;
    }
  
    return (
      <div className={classNames(css.root, className)}>
        <p className={css.price}>{formatMoney(intl, price)}</p>
        <div className={css.perUnit}>
          {perUnit}
        </div>
      </div>
    );
  };

export default PriceMaybe;