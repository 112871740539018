import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { Heading, NamedLink, IconEmailSent, InlineTextButton, IconClose, Modal } from '..';
import { bool, string, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import classNames from 'classnames';

import css from './EmailVerificationModal.module.css';

const EmailVerificationModal = props => {
  const { isOpen, onClose, id, className } = props;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = useSelector(
    state => state.user
  );
  const dispatch = useDispatch();

  const name = currentUser?.attributes.profile?.firstName;
  const email = <span className={css.email}>{currentUser?.attributes.email}</span>;
  const onResendVerificationEmail = () => dispatch(sendVerificationEmail());
  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'EmailVerificationModal.resendFailedTooManyRequests'
    : 'EmailVerificationModal.resendFailed';

  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
      <FormattedMessage id="EmailVerificationModal.resendEmailLinkText" />
    </InlineTextButton>
  );

  const fixEmailLink = (
    <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
      <FormattedMessage id="EmailVerificationModal.fixEmailLinkText" />
    </NamedLink>
  );
  const modalClassName = classNames(className, css.modal);

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      contentClassName={css.modalContent}
      className={modalClassName}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <IconEmailSent className={css.modalIcon} />
      <Heading as="h1" rootClassName={css.modalTitle}>
        <FormattedMessage id="EmailVerificationModal.verifyEmailTitle" values={{ name }} />
      </Heading>
      <p className={css.modalMessage}>
        <FormattedMessage id="EmailVerificationModal.verifyEmailText" values={{ email }} />
      </p>
      {resendErrorMessage}
      <div className={css.bottomWrapper}>
        <p className={css.modalHelperText}>
          {sendVerificationEmailInProgress ? (
            <FormattedMessage id="EmailVerificationModal.sendingEmail" />
          ) : (
            <FormattedMessage
              id="EmailVerificationModal.resendEmail"
              values={{ resendEmailLink }}
            />
          )}
        </p>
        <p className={css.modalHelperText}>
          <FormattedMessage id="EmailVerificationModal.fixEmail" values={{ fixEmailLink }} />
        </p>
      </div>
    </Modal>
  );
};

EmailVerificationModal.propTypes = {
  isOpen: bool,
  onClose: func,
  id: string.isRequired,
  className: string,
};

EmailVerificationModal.defaultProps = {};

export default EmailVerificationModal;
