/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  // Offer: this section may use in future
  // MADE_OFFER: 'transition/made-offer',
  // MADE_OFFER_AFTER_INQUIRY: 'transition/made-offer-after-inquiry',
  // PROVIDER_OFFER: 'transition/provider-offer',
  // PROVIDER_ACCEPT_OFFER: 'transition/provider-accept-offer',
  // PROVIDER_DECLINE_OFFER: 'transition/provider-decline-offer',
  // CUSTOMER_OFFER_EXPIRE: 'transition/customer-offer-expire',
  // CUSTOMER_OFFER: 'transition/customer-offer',
  // CUSTOMER_ACCEPT_OFFER: 'transition/customer-accept-offer',
  // CUSTOMER_DECLINE_OFFER: 'transition/customer-decline-offer',
  // PROVIDER_OFFER_EXPIRE: 'transition/provider-offer-expire',
  // REQUEST_PAYMENT_AFTER_OFFER: 'transition/request-payment-after-offer',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  // Customer, provider and admin can decline the booking.
  ACCEPT: 'transition/accept',
  PROVIDER_DECLINE: 'transition/provider-decline',
  CUSTOMER_DECLINE: 'transition/customer-decline',
  ADMIN_DECLINE: 'transition/admin-decline',
  EXPIRE: 'transition/expire',

  // Customer, provider and admin can cancel the booking.
  CUSTOMER_CANCEL: 'transition/customer-cancel',
  ADMIN_CANCEL: 'transition/admin-cancel',
  PROVIDER_REQUEST_CANCEL: 'transition/provider-request-cancel',
  ADMIN_ACCEPT_CANCEL: 'transition/admin-accept-cancel',
  ADMIN_DECLINE_CANCEL: 'transition/admin-decline-cancel',

  // Booking start
  DEPART: 'transition/depart',

  // Dispute 1: after booking start, customer and provider can dispute the booking
  CUSTOMER_DISPUTE_1: 'transition/customer-dispute-1',
  PROVIDER_DISPUTE_1: 'transition/provider-dispute-1',
  ADMIN_DISPUTE_1: 'transition/admin-dispute-1',
  ADMIN_CANCEL_DISPUTE_1: 'transition/admin-cancel-dispute-1',
  ADMIN_CHARGE_FULL_DEPOSIT_DISPUTE_1: 'transition/admin-charge-full-deposit-dispute-1',
  ADMIN_CHARGE_PARTIAL_DEPOSIT_DISPUTE_1: 'transition/admin-charge-partial-deposit-dispute-1',
  ADMIN_REFUND_FULL_DEPOSIT_RENTAL_FEE_BOOKING_FEE: 'transition/admin-refund-full-deposit-rental-fee-booking-fee',
  EXPIRE_DISPUTE_1: 'transition/expire-dispute-1',

  // Booking end
  RETURN: 'transition/return',

  // Dispute 2: after booking end, customer and provider can dispute the booking
  CUSTOMER_DISPUTE_2: 'transition/customer-dispute-2',
  PROVIDER_DISPUTE_2: 'transition/provider-dispute-2',
  ADMIN_DISPUTE_2: 'transition/admin-dispute-2',
  ADMIN_CANCEL_DISPUTE_2: 'transition/admin-cancel-dispute-2',
  ADMIN_CHARGE_FULL_DEPOSIT_DISPUTE_2: 'transition/admin-charge-full-deposit-dispute-2',
  ADMIN_CHARGE_PARTIAL_DEPOSIT_DISPUTE_2: 'transition/admin-charge-partial-deposit-dispute-2',
  EXPIRE_DISPUTE_2: 'transition/expire-dispute-2',

  // Complete booking
  COMPLETE: 'transition/complete',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',

  // Fine received
  FINE_RECEIVED_AFTER_COMPLETED: 'transition/fine-received-after-completed',
  FINE_RECEIVED_AFTER_DISPUTE_RESOLVED_1: 'transition/fine-received-after-dispute-resolved-1',
  FINE_RECEIVED_AFTER_REVIEWED_BY_CUSTOMER: 'transition/fine-received-after-reviewed-by-customer',
  FINE_RECEIVED_AFTER_REVIEWED_BY_PROVIDER: 'transition/fine-received-after-reviewed-by-provider',
  FINE_RECEIVED_AFTER_REVIEWED_PERIOD: 'transition/fine-received-after-reviewed-period',
  SEND_PAYMENT_LINK_TO_CUSTOMER: 'transition/send-payment-link-to-customer',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  // May use offer in future
  // CUSTOMER_OFFERED: 'customer-offered',
  // PROVIDER_OFFERED: 'provider-offered',
  // OFFER_ACCEPTED: 'offer-accepted',
  // OFFER_DECLINED: 'offer-declined',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  CANCELLATION_REQUESTED: 'cancellation-requested',
  CANCELED: 'canceled',
  DEPARTURED: 'departured',
  DISPUTED_1: 'disputed-1',
  RETURNED: 'returned',
  DISPUTED_2: 'disputed-2',
  DISPUTE_RESOLVED_1: 'dispute-resolved-1',
  COMPLETED: 'completed',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  DOCUMENT_UPLOADED: 'document-uploaded',
  DISPUTE_RESOLVED_2: 'dispute-resolved-2',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
// offer may use in future, please don't remove
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-booking/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        // [transitions.MADE_OFFER]: states.CUSTOMER_OFFERED,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
        // [transitions.MADE_OFFER_AFTER_INQUIRY]: states.CUSTOMER_OFFERED,
      },
    },

    // [states.CUSTOMER_OFFERED]: {
    //   on: {
    //     [transitions.PROVIDER_OFFER]: states.PROVIDER_OFFERED,
    //     [transitions.PROVIDER_ACCEPT_OFFER]: states.OFFER_ACCEPTED,
    //     [transitions.PROVIDER_DECLINE_OFFER]: states.OFFER_DECLINED,
    //     [transitions.CUSTOMER_OFFER_EXPIRE]: states.OFFER_DECLINED,
    //   },
    // },
    // [states.PROVIDER_OFFERED]: {
    //   on: {
    //     [transitions.CUSTOMER_OFFER]: states.CUSTOMER_OFFERED,
    //     [transitions.CUSTOMER_ACCEPT_OFFER]: states.OFFER_ACCEPTED,
    //     [transitions.CUSTOMER_DECLINE_OFFER]: states.OFFER_DECLINED,
    //     [transitions.PROVIDER_OFFER_EXPIRE]: states.OFFER_DECLINED,
    //   },
    // },

    // [states.OFFER_ACCEPTED]: {
    //   on: {
    //     [transitions.REQUEST_PAYMENT_AFTER_OFFER]: states.PENDING_PAYMENT,
    //   },
    // },
    // [states.OFFER_DECLINED]: {},

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.PROVIDER_DECLINE]: states.DECLINED,
        [transitions.CUSTOMER_DECLINE]: states.DECLINED,
        [transitions.ADMIN_DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.DECLINED,
      },
    },

    [states.DECLINED]: {},
    [states.ACCEPTED]: {
      on: {
        [transitions.CUSTOMER_CANCEL]: states.CANCELED,
        [transitions.ADMIN_CANCEL]: states.CANCELED,
        [transitions.PROVIDER_REQUEST_CANCEL]: states.CANCELLATION_REQUESTED,
        [transitions.DEPART]: states.DEPARTURED,
      },
    },
    [states.CANCELLATION_REQUESTED]: {
      on: {
        [transitions.ADMIN_ACCEPT_CANCEL]: states.CANCELED,
        [transitions.ADMIN_DECLINE_CANCEL]: states.ACCEPTED,
      }
    },

    [states.CANCELED]: {},
    [states.DEPARTURED]: {
      on: {
        [transitions.CUSTOMER_DISPUTE_1]: states.DISPUTED_1,
        [transitions.PROVIDER_DISPUTE_1]: states.DISPUTED_1,
        [transitions.ADMIN_DISPUTE_1]: states.DISPUTED_1,
        [transitions.RETURN]: states.RETURNED,
      },
    },

    [states.DISPUTED_1]: {
      on: {
        [transitions.ADMIN_CANCEL_DISPUTE_1]: states.DEPARTURED,
        [transitions.ADMIN_CHARGE_FULL_DEPOSIT_DISPUTE_1]: states.DISPUTE_RESOLVED_1,
        [transitions.ADMIN_CHARGE_PARTIAL_DEPOSIT_DISPUTE_1]: states.DISPUTE_RESOLVED_1,
        [transitions.EXPIRE_DISPUTE_1]: states.DISPUTE_RESOLVED_1,
        [transitions.ADMIN_REFUND_FULL_DEPOSIT_RENTAL_FEE_BOOKING_FEE]: states.DISPUTE_RESOLVED_2,
      }
    },

    [states.DISPUTE_RESOLVED_1]: {
      on: {
        [transitions.FINE_RECEIVED_AFTER_DISPUTE_RESOLVED_1]: states.DOCUMENT_UPLOADED,
      }
    },

    [states.RETURNED]: {
      on: {
        [transitions.CUSTOMER_DISPUTE_2]: states.RETURNED,
        [transitions.PROVIDER_DISPUTE_2]: states.DISPUTED_2,
        [transitions.ADMIN_DISPUTE_2]: states.DISPUTED_2,
        [transitions.COMPLETE]: states.COMPLETED,
      },
    },

    [states.DISPUTED_2]: {
      on: {
        [transitions.ADMIN_CANCEL_DISPUTE_2]: states.DISPUTE_RESOLVED_1,
        [transitions.ADMIN_CHARGE_FULL_DEPOSIT_DISPUTE_2]: states.DISPUTE_RESOLVED_1,
        [transitions.ADMIN_CHARGE_PARTIAL_DEPOSIT_DISPUTE_2]: states.DISPUTE_RESOLVED_1,
        [transitions.EXPIRE_DISPUTE_2]: states.DISPUTE_RESOLVED_1,
        [transitions.COMPLETE]: states.COMPLETED,
      }
    },

    [states.DOCUMENT_UPLOADED]: {},

    [states.COMPLETED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
        [transitions.FINE_RECEIVED_AFTER_COMPLETED]: states.DOCUMENT_UPLOADED,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.FINE_RECEIVED_AFTER_REVIEWED_BY_CUSTOMER]: states.DOCUMENT_UPLOADED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.FINE_RECEIVED_AFTER_REVIEWED_BY_PROVIDER]: states.DOCUMENT_UPLOADED,
      },
    },
    [states.REVIEWED]: {
      on: {
        [transitions.FINE_RECEIVED_AFTER_REVIEWED_PERIOD]: states.DOCUMENT_UPLOADED,
      }
    },
    [states.DISPUTE_RESOLVED_2]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    // offer may use in future, please don't remove
    // transitions.MADE_OFFER,
    // transitions.MADE_OFFER_AFTER_INQUIRY,
    // transitions.PROVIDER_OFFER,
    // transitions.PROVIDER_ACCEPT_OFFER,
    // transitions.PROVIDER_DECLINE_OFFER,
    // transitions.CUSTOMER_OFFER_EXPIRE,
    // transitions.CUSTOMER_OFFER,
    // transitions.CUSTOMER_ACCEPT_OFFER,
    // transitions.CUSTOMER_DECLINE_OFFER,
    // transitions.PROVIDER_OFFER_EXPIRE,

    transitions.CONFIRM_PAYMENT,

    transitions.ACCEPT,
    transitions.PROVIDER_DECLINE,
    transitions.CUSTOMER_DECLINE,
    transitions.ADMIN_DECLINE,
    transitions.EXPIRE,

    transitions.CUSTOMER_CANCEL,
    transitions.ADMIN_CANCEL,
    transitions.ADMIN_ACCEPT_CANCEL,

    transitions.DEPART,
    transitions.CUSTOMER_DISPUTE_1,
    transitions.PROVIDER_DISPUTE_1,
    transitions.ADMIN_DISPUTE_1,
    transitions.ADMIN_CANCEL_DISPUTE_1,
    transitions.ADMIN_CHARGE_FULL_DEPOSIT_DISPUTE_1,
    transitions.ADMIN_CHARGE_PARTIAL_DEPOSIT_DISPUTE_1,
    transitions.ADMIN_REFUND_FULL_DEPOSIT_RENTAL_FEE_BOOKING_FEE,
    transitions.EXPIRE_DISPUTE_1,

    transitions.RETURN,
    transitions.CUSTOMER_DISPUTE_2,
    transitions.PROVIDER_DISPUTE_2,
    transitions.ADMIN_DISPUTE_2,
    transitions.ADMIN_CANCEL_DISPUTE_2,
    transitions.ADMIN_CHARGE_FULL_DEPOSIT_DISPUTE_2,
    transitions.ADMIN_CHARGE_PARTIAL_DEPOSIT_DISPUTE_2,
    transitions.EXPIRE_DISPUTE_2,

    transitions.COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,

    transitions.FINE_RECEIVED_AFTER_COMPLETED,
    transitions.FINE_RECEIVED_AFTER_DISPUTE_RESOLVED_1,
    transitions.FINE_RECEIVED_AFTER_REVIEWED_BY_CUSTOMER,
    transitions.FINE_RECEIVED_AFTER_REVIEWED_BY_PROVIDER,
    transitions.FINE_RECEIVED_AFTER_REVIEWED_PERIOD,
    transitions.SEND_PAYMENT_LINK_TO_CUSTOMER,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    transitions.REQUEST_PAYMENT,
    transitions.REQUEST_PAYMENT_AFTER_INQUIRY,
    // May use offer in future
    // transitions.MADE_OFFER,
    // transitions.CUSTOMER_OFFER,
    // transitions.PROVIDER_OFFER,
    // transitions.REQUEST_PAYMENT_AFTER_OFFER,
  ].includes(transition);
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.FINE_RECEIVED_AFTER_COMPLETED,
    transitions.FINE_RECEIVED_AFTER_DISPUTE_RESOLVED_1,
    transitions.FINE_RECEIVED_AFTER_REVIEWED_BY_CUSTOMER,
    transitions.FINE_RECEIVED_AFTER_REVIEWED_BY_PROVIDER,
    transitions.FINE_RECEIVED_AFTER_REVIEWED_PERIOD,
    transitions.SEND_PAYMENT_LINK_TO_CUSTOMER,

    // offer may use in future, please don't remove
    // transitions.EXPIRE_REVIEW_PERIOD,
    // transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    // transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
// Use custom Stripe, so don't need to check these transitions
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,

    transitions.EXPIRE,
    transitions.CUSTOMER_DECLINE,
    transitions.PROVIDER_DECLINE,
    transitions.ADMIN_DECLINE,

    transitions.ADMIN_CANCEL,
    transitions.ADMIN_ACCEPT_CANCEL,
    transitions.CUSTOMER_CANCEL,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PREAUTHORIZED];
