import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFileImage.module.css';

const IconFileDefault = props => {
  const { className, rootClassName, width = 24, height = 24 } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg width={width} height={height} x="0px" y="0px" viewBox="0 0 512 512" className={classes}>
      <path
        style={{ fill: '#E2E7F0' }}
        d="M104,0C86.328,0,72,14.328,72,32v448c0,17.672,14.328,32,32,32h304c17.672,0,32-14.328,32-32V128
	L312,0H104z"
      />
      <g>
        <path
          style={{ fill: '#9DACBA' }}
          d="M344,128h96L312,0v96C312,113.672,326.328,128,344,128z"
        />
        <path
          style={{ fill: '#9DACBA' }}
          d="M192,160h128c4.416,0,8,3.584,8,8l0,0c0,4.416-3.584,8-8,8H192c-4.416,0-8-3.584-8-8l0,0
		C184,163.584,187.584,160,192,160z"
        />
        <path
          style={{ fill: '#9DACBA' }}
          d="M144,328h224c4.416,0,8,3.584,8,8l0,0c0,4.416-3.584,8-8,8H144c-4.416,0-8-3.584-8-8l0,0
		C136,331.584,139.584,328,144,328z"
        />
        <path
          style={{ fill: '#9DACBA' }}
          d="M144,424h128c4.416,0,8,3.584,8,8l0,0c0,4.416-3.584,8-8,8H144c-4.416,0-8-3.584-8-8l0,0
		C136,427.584,139.584,424,144,424z"
        />
        <path
          style={{ fill: '#9DACBA' }}
          d="M144,376h224c4.416,0,8,3.584,8,8l0,0c0,4.416-3.584,8-8,8H144c-4.416,0-8-3.584-8-8l0,0
		C136,379.584,139.584,376,144,376z"
        />
        <path
          style={{ fill: '#9DACBA' }}
          d="M144,280h224c4.416,0,8,3.584,8,8l0,0c0,4.416-3.584,8-8,8H144c-4.416,0-8-3.584-8-8l0,0
		C136,283.584,139.584,280,144,280z"
        />
        <path
          style={{ fill: '#9DACBA' }}
          d="M144,232h224c4.416,0,8,3.584,8,8l0,0c0,4.416-3.584,8-8,8H144c-4.416,0-8-3.584-8-8l0,0
		C136,235.584,139.584,232,144,232z"
        />
      </g>
      <path
        style={{ fill: '#C9D5E3' }}
        d="M344,128l75.28,75.28c3.024,3.008,4.72,7.096,4.72,11.36V480c0,8.84-7.16,16-16,16H120
	c-8.84,0-16,7.16-16,16h304c17.672,0,32-14.328,32-32V128H344z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconFileDefault.defaultProps = {
  className: null,
  rootClassName: null,
  type: null,
};

IconFileDefault.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default IconFileDefault;
