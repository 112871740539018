import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFileImage.module.css';

const IconFileJpg = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg viewBox="0 0 512 512" className={classes}>
      <path
        style={{ fill: '#E2E5E7' }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path style={{ fill: '#B0B7BD' }} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
      <polygon style={{ fill: '#CAD1D8' }} points="480,224 384,128 480,128 " />
      <path
        style={{ fill: '#50BEE8' }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
	V416z"
      />
      <g>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M141.968,303.152c0-10.752,16.896-10.752,16.896,0v50.528c0,20.096-9.6,32.256-31.728,32.256
		c-10.88,0-19.952-2.96-27.888-13.184c-6.528-7.808,5.76-19.056,12.416-10.88c5.376,6.656,11.136,8.192,16.752,7.936
		c7.152-0.256,13.44-3.472,13.568-16.128v-50.528H141.968z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M181.344,303.152c0-4.224,3.328-8.832,8.704-8.832H219.6c16.64,0,31.616,11.136,31.616,32.48
		c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816
		L181.344,303.152L181.344,303.152z M198.24,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504
		c0-8.944-6.784-16.368-15.36-16.368H198.24z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M342.576,374.16c-9.088,7.552-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.344-45.936-45.808
		c0-25.824,20.096-45.904,47.072-45.904c10.112,0,21.232,3.44,29.168,11.248c7.792,7.664-3.456,19.056-11.12,12.288
		c-4.736-4.608-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.464,29.296,30.464
		c7.792,0,14.448-2.32,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.616,0-15.616h25.584c4.736,0,9.072,3.584,9.072,7.552v27.248
		C345.76,369.568,344.752,371.712,342.576,374.16z"
        />
      </g>
      <path
        style={{ fill: '#CAD1D8' }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconFileJpg.defaultProps = {
  className: null,
  rootClassName: null,
  type: null,
};

IconFileJpg.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default IconFileJpg;
