import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFileImage.module.css';

const IconFilePng = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg viewBox="0 0 512 512" className={classes}>
      <path
        style={{ fill: '#E2E5E7' }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path style={{ fill: '#B0B7BD' }} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
      <polygon style={{ fill: '#CAD1D8' }} points="480,224 384,128 480,128 " />
      <path
        style={{ fill: '#A066AA' }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
 V416z"
      />
      <g>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M92.816,303.152c0-4.224,3.312-8.848,8.688-8.848h29.568c16.624,0,31.6,11.136,31.6,32.496
   c0,20.224-14.976,31.472-31.6,31.472H109.68v16.896c0,5.648-3.552,8.832-8.176,8.832c-4.224,0-8.688-3.184-8.688-8.832
   C92.816,375.168,92.816,303.152,92.816,303.152z M109.68,310.432v31.856h21.376c8.56,0,15.344-7.552,15.344-15.488
   c0-8.96-6.784-16.368-15.344-16.368L109.68,310.432L109.68,310.432z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M178.976,304.432c0-4.624,1.024-9.088,7.68-9.088c4.592,0,5.632,1.152,9.072,4.464l42.336,52.976
   v-49.632c0-4.224,3.696-8.848,8.064-8.848c4.608,0,9.072,4.624,9.072,8.848v72.016c0,5.648-3.456,7.792-6.784,8.832
   c-4.464,0-6.656-1.024-10.352-4.464l-42.336-53.744v49.392c0,5.648-3.456,8.832-8.064,8.832s-8.704-3.184-8.704-8.832v-70.752
   H178.976z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M351.44,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808
   c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.808,7.664-3.456,19.056-11.12,12.288
   c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448
   c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248
   C354.624,369.552,353.616,371.712,351.44,374.16z"
        />
      </g>
      <path
        style={{ fill: '#CAD1D8' }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconFilePng.defaultProps = {
  className: null,
  rootClassName: null,
  type: null,
};

IconFilePng.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default IconFilePng;
