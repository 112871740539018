import React from 'react';
import PropTypes from 'prop-types';

import IconFileJpg from './IconFileJpg';
import IconFilePng from './IconFilePng';
import IconFileDefault from './IconFileDefault';
import IconFilePdf from './IconFilePdf';

const IMAGE_ICONS = {
  'image/jpg': IconFileJpg,
  'image/jpeg': IconFileJpg,
  'image/png': IconFilePng,
  'application/pdf': IconFilePdf,
};

const IconFileImage = props => {
  const { className, rootClassName, type } = props;
  const IconComponent = IMAGE_ICONS[type] ?? IconFileDefault;

  return <IconComponent className={className} rootClassName={rootClassName} />;
};

const { string } = PropTypes;

IconFileImage.defaultProps = {
  className: null,
  rootClassName: null,
  type: null,
};

IconFileImage.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default IconFileImage;
