import React, { useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '..';
import { useDropzone } from 'react-dropzone';
import css from './FieldUploadFile.module.css';

const FieldUploadFileComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    onUnmount,
    showErrorMessage,
    onChange: externalOnChange,
    disabled: fieldDisabled,
    isDraggable,
    accept,
    disabled,
    maxFiles,
    maxSize,
    validator,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState(false);

  const onDrop = useCallback(files => {
    setErrors(null);
    externalOnChange?.(files);
  }, []);

  const onDropRejected = useCallback(fileRejections => {
    const errors = fileRejections.reduce((fileErrors, file) => fileErrors.concat(file.errors), []);
    setErrors(errors);
  }, []);

  const { multiple } = input;

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: accept.reduce((accept, currentMimeType) => ({ ...accept, [currentMimeType]: [] }), {}),
    multiple,
    noDrag: !isDraggable || disabled,
    onDrop,
    onDropRejected,
    disabled,
    maxFiles,
    maxSize,
    validator,
  });

  const { invalid, error } = meta;

  const errorText = customErrorText || error || errors?.[0]?.message;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(invalid && error) || errorText;

  const fieldMeta = { touched, error: hasError ? errorText : null };

  const classes = classNames(rootClassName || css.root, {
    [css.dragAccepted]: isDragAccept,
    [css.dragRejected]: isDragReject,
  });

  const { onChange: dropzoneOnChange, ...restFieldProps } = getInputProps();
  const onChange = e => {
    setTouched(true);
    dropzoneOnChange(e);
  };

  return (
    <div className={className}>
      <div className={classes} {...getRootProps()} {...rest}>
        <label htmlFor={input.name} className={css.addFile}>
          {fieldDisabled ? null : <input {...input} {...restFieldProps} onChange={onChange} />}
          <span className={css.chooseFileText}>
            <span className={css.chooseFile}>{label}</span>
          </span>
        </label>
      </div>
      {showErrorMessage && <ValidationError fieldMeta={fieldMeta} rootClassName={css.errorText} />}
    </div>
  );
};

FieldUploadFileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  accept: ['image/*'],
  showErrorMessage: true,
  isDraggable: true,
  disabled: false,
};

FieldUploadFileComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: node,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
  accept: arrayOf(string),
  isDraggable: bool,
  disabled: bool,
  showErrorMessage: bool,
  onChange: func,
  validator: func,
  maxFiles: number.isRequired,
  maxSize: number.isRequired,
};

const FieldUploadFile = props => <Field component={FieldUploadFileComponent} {...props} />;

export default FieldUploadFile;
